

:root{
  background: black;
  color: white;
}

.App {
  text-align: center;
}

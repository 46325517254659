@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.segoe {
  font-family: "Segoe UI Semibold";
  @apply font-normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  @apply text-3xl md:text-6xl xl:text-8xl font-black;
}

h2 {
  @apply  xl:text-6xl font-bold;
}

h3 {
  @apply text-base md:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold;
}

p {
  @apply text-xs lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl;
}

button {
  @apply px-3 py-2 bg-orange-600 rounded-md text-2xl font-semibold;
}

.hover-effect {
  @apply italic underline decoration-1 underline-offset-8 decoration-orange-600 hover:decoration-transparent hover:text-white relative after:absolute after:w-0  after:bg-orange-600/70 after:rounded-md after:-z-10 after:block after:-inset-1 after:transition-all after:duration-300 hover:after:w-[calc(100%+0.5rem)];
}

a {
  @apply font-semibold italic underline decoration-2 underline-offset-4 decoration-orange-600;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 1.5s ease-in-out forwards;
}
